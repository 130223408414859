import React from 'react'
import { useSelector } from 'react-redux'
import { getLocationPayload } from 'lib/location/selectors'
import { GlobalState } from 'types/redux'
import PageLocationType from 'models/page-location-type'
import LanguageType from 'models/language-type'
import FrontlinesOverview from './FrontlinesOverview'
import FrontlinesFaction from './FrontlinesFaction'
import WarzoneProviders from './WarzoneProviders'

const CONTENTFUL_SLUG = 'frontlines'

const renderPage = (
    location: PageLocationType,
    language: LanguageType
): JSX.Element => {
    if (!location.subpage) {
        return (
            <FrontlinesOverview prefix={CONTENTFUL_SLUG} language={language} />
        )
    }
    if (location.subpage) {
        return (
            <FrontlinesFaction
                subpage={location.subpage}
                prefix={CONTENTFUL_SLUG}
                language={language}
            />
        )
    }
}

const FrontlinesController = (): JSX.Element => {
    const location = useSelector((state: GlobalState) =>
        getLocationPayload(state)
    )
    const language = useSelector((state: GlobalState) => state.language)

    return <WarzoneProviders>{renderPage(location, language)}</WarzoneProviders>
}

export default FrontlinesController
